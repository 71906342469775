<script setup lang="ts">
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const props = defineProps<{
  button: any;
  btnType: string;
}>();

const button = props.button;
const btnType = props.btnType;

const replaceDomain = (link: any) => {
  if(!link) {
    return link;
  }
  link = link.replace ('https://zebra-fashion-preview.vercel.app/fr/', '/')
  link = link.replace('https://zebra-fashion-preview.vercel.app/', '/');
  link = link.replace ('https://www.zebrafashion.com/fr-FR/', '/')
  link = link.replace('https://www.zebrafashion.com/', '/');
  return link;
}

</script>
<template>
  <template v-if="button && button.title">
    <NuxtLink v-if="button.url"
        :class="{
          'btn_secondary': btnType == 'secondary',
          'btn_primary': btnType == 'primary',
          'btn-outline': btnType == 'outline',
        }"
        class="w-auto py-2 px-4"
        :to="formatLink(replaceDomain(button.url))"
        :target="button.target"
    >
      {{button.title}}
    </NuxtLink>
  </template>
</template>